<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="25" size="40" ref="toppane">
<!--			<div v-if="!isMeeting" style="max-height: 100%" class="overflow-y-auto"> -->
				<div v-if="!isMeeting">
					<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
						:options.sync="options" :server-items-length="totalItems"
						:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
						:loading="loading" loading-text="加载中..." show-select single-select>
						<template v-slot:top>
							<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px" ref="tabletopbar">
								<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
									<v-btn>全部待评</v-btn>
									<v-btn>今日待评</v-btn>
									<v-btn>明日待评</v-btn>
									<v-btn>评估完成</v-btn>
								</v-btn-toggle>
								<v-select label="评估方式" persistent-placeholder placeholder="全部" clearable v-model="evalway" :items="['视频评估','到院评估']" dense hide-details
									style="flex: 0 1 auto;max-width:130px;" @change="fetchData"/>
								<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto"
									append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
								<v-btn-toggle v-if="canMeeting" dense class="ml-4">
									<v-btn color="primary" :disabled="isMeeting||(selected.length===0)||(selected[0].evalway!=='视频评估')" @click="startMeeting">视频评估</v-btn>
								</v-btn-toggle>
							</div>
							<v-divider/>
						</template>
						<template v-slot:item.ho="{ item }">
							<v-tooltip v-if="!!item.HistoryOperatives" bottom>
								<template v-slot:activator="{ on }">
									<v-icon v-on="on" color="#ce5925" @click.stop="showHistoryOper(item)">mdi-account-heart-outline</v-icon>
								</template>
								<span v-html="item.HistoryOperatives"/>
							</v-tooltip>
						</template>
						<template v-slot:item.actions="{ item }">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-icon v-on="on" @click="editNote(item)" class="mr-1" :color="item.color">edit_note</v-icon>
								</template>
								患者备注
							</v-tooltip>
						</template>
						<template v-slot:item.insurance="{ item }">
							<span :class="item.payment.insurance ? 'teal--text text--accent-4 font-weight-bold' : 'black--text'">{{getInsurance(item)}}</span>
						</template>
						<template v-slot:item.bmi="{ item }">
							<span :style="{color:getBMI(item) >30 ? 'red':'black'}">{{getBMI(item)}}</span>
						</template>
						<template v-slot:item.apntEvalDate="{ item }">
							{{formatTime(item.apntEvalDate)}}
						</template>
						<template v-slot:item.idcard="{ item }">
							{{getMaskedIdcard(item)}}
						</template>
						<template v-slot:item.age="{ item }">
							{{getAge(item)}}
						</template>
						<template v-slot:item.opts="{ item }">
							{{getOptsStr(item)}}
						</template>
						<template v-slot:item.apntEvalDoctor="props">
							<v-edit-dialog large :return-value.sync="props.item.apntEvalDoctor"
								save-text="保存" cancel-text="取消" @save="saveItem('apntEvalDoctor', props)">
								{{props.item.apntEvalDoctor}}
								<template v-slot:input>
									<v-select :items="doctors_p" v-model="props.item.apntEvalDoctor"/>
								</template>
							</v-edit-dialog>
						</template>
					</v-data-table>
				</div>
				<div v-else>
					<meeting2 ref="meeting" :userid="meetingUserId" :roomid="meetingRoomId" @leave="isMeeting=false"/>
				</div>
			</pane>
			<pane min-size="20" size="60" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto">
                <v-tabs v-model="tab" style="position:sticky;top:0;z-index:99;">
					<v-tab><v-icon>mdi-hospital-box-outline</v-icon>基本信息</v-tab>
                    <v-tab>健康问卷</v-tab>
                    <v-tab><v-icon>mdi-hospital-box-outline</v-icon>病历记录</v-tab>
					<v-tab :disabled="!selected[0].payment.insurance"><v-icon>mdi-hospital-box-outline</v-icon>保险面评</v-tab>
                    <v-tab>检查项清单</v-tab>
                    <v-tab><v-icon>mdi-hospital-box-outline</v-icon>检查结果</v-tab>
                    <v-tab :disabled="isED">评估问卷</v-tab>
                    <v-tab :disabled="isED">视频评估</v-tab>
                    <!--<v-tab :disabled="!selected[0].payment.insurance">保险术前结果</v-tab>-->
				</v-tabs>
				<v-tabs-items v-model="tab">
                    <v-tab-item>
						<v-list width="400" dense>
							<v-list-item v-for="h in headers" :key="h.value" dense style="min-height:32px">
								<v-list-item-title>{{h.text}}</v-list-item-title>
								<v-list-item-subtitle>{{ getText(h) }}</v-list-item-subtitle>
							</v-list-item>
						</v-list>
                    </v-tab-item>
                    <v-tab-item>
                        <v-list-item v-for="(item, i) in selected[0].QA.questionnaire" :key="item.category" dense>
                            <v-list-item-content>
                                <v-list-item-title>{{item.category}}</v-list-item-title>
                                <v-checkbox v-for="q in item.questions" :key="q.Q" :label="q.Q" :input-value="q.A==='1'"
                                    readonly class="ml-6 mt-0" hide-details>
                                </v-checkbox>
                            </v-list-item-content>
                        </v-list-item>
                    </v-tab-item>
                    <v-tab-item>
						<div>
							<v-btn class="ml-8 mt-6 mb-4" rounded width="120" color="primary" @click.stop="addRecord">添加病历</v-btn>
							<diagnose-history :items="MedicalHistory" @edit="editRecord" @remove="removeRecord"/>
						</div>
                    </v-tab-item>
					<v-tab-item :disabled="!selected[0].payment.insurance">
						<v-form style="padding:12px 32px;width:600px;" :disabled="!selected[0].payment.insurance || lockedConclusion2" ref="diagform">
							<v-checkbox v-model="evalConclusion.gastroPrompt1" label="检前7天停抗凝药" dense hide-details/>
							<v-checkbox v-model="evalConclusion.gastroPrompt2" label="检查当天停降糖药" dense hide-details/>
							<v-checkbox v-model="evalConclusion.gastroPrompt4" label="检查当天正常服降压药" dense hide-details/>
							<v-radio-group v-model="evalConclusion.gastroPrompt3" dense row :class="{'d-none':onlyW}" class="mt-2">
								<v-radio label="3包清肠药" :value="false"></v-radio>
								<v-radio label="4包清肠药" :value="true" class="ml-6"></v-radio>
							</v-radio-group>
							<v-text-field v-model="evalConclusion.notice" label="关注" outlined/>
							<v-radio-group v-model="evalConclusion.anesConclusion" row dense :rules="acRules">
								<v-radio label="无痛" value="无痛"></v-radio>
								<v-radio label="普通" value="普通"></v-radio>
								<v-radio label="未通过" value="未通过"></v-radio>
							</v-radio-group>
							<v-btn rounded color="primary" width="120" class="mt-2 mb-4" @click.stop="saveDiagConclusion"
								:loading="loading" :disabled="loading||!selected[0].payment.insurance||lockedConclusion2">提交结论</v-btn>
						</v-form>
					</v-tab-item>
					<v-tab-item>
						<exam-list :patient="selected[0]"/>
					</v-tab-item>
                    <v-tab-item>
                        <v-container fluid>
                            <v-row>
                                <v-card v-for="(r,idx) in eeresults" :key="idx" class="ma-2" max-width="300">
                                    <v-img :src="r" lazy-src="../assets/logo.png" width="300" height="300" @click="showImage(idx)">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-card>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item :disabled="isED">
						<eval-q ref="evalq" :patient="selected[0]"/>
						<v-btn color="primary" :disabled="loading" @click.stop="saveEvalQ" width="100" style="margin-left:60px" class="mt-2 mb-6">保存</v-btn>
						<v-btn color="primary" :disabled="loading" @click.stop="printEvalQ" width="100" class="mt-2 mb-6 ml-4">打印</v-btn>
						<v-btn color="primary" :disabled="loading" @click.stop="printEvalMR" width="100" class="mt-2 mb-6 ml-4">生成病历</v-btn>
                    </v-tab-item>
                    <v-tab-item :disabled="isED">
					<v-container>
						<v-row justify="start">
							<v-col md="4">
								<v-form :readonly="lockedConclusion1" ref="ecform">
									<v-radio-group v-model="evalConclusion.operationQueue" row dense :rules="acRules">
										<v-radio label="常规" value="常规"></v-radio>
										<v-radio label="第一台" value="第一台"></v-radio>
									</v-radio-group>
									<v-checkbox v-model="evalConclusion.gastroPrompt1" label="检前7天停抗凝药" dense hide-details/>
									<v-checkbox v-model="evalConclusion.gastroPrompt2" label="检查当天停降糖药" dense hide-details/>
									<v-checkbox v-model="evalConclusion.gastroPrompt4" label="检查当天正常服降压药" dense hide-details/>
									<v-radio-group v-model="evalConclusion.gastroPrompt3" dense row :class="{'d-none':onlyW}" class="mt-2">
										<v-radio label="3包清肠药" :value="false"></v-radio>
										<v-radio label="4包清肠药" :value="true" class="ml-6"></v-radio>
									</v-radio-group>
									<v-text-field v-model="evalConclusion.notice" label="关注" outlined/>
									<v-radio-group v-model="evalConclusion.anesConclusion" row dense :rules="acRules">
										<v-radio label="无痛" value="无痛"></v-radio>
										<v-radio label="普通" value="普通"></v-radio>
										<v-radio label="未通过" value="未通过"></v-radio>
									</v-radio-group>
									<v-btn rounded color="primary" width="160" class="mt-0 mb-4" @click="saveConclusion1"
										:loading="savingConclusion1" :disabled="savingConclusion1||lockedConclusion1">保存评估结论</v-btn>
									<v-btn v-if="canEdit" rounded outlined color="primary" class="mt-0 mb-4 ml-4" @click="lockedConclusion1=false">编辑</v-btn>
								</v-form>
							</v-col>
						</v-row>
					</v-container>
                    </v-tab-item>
					<!--
                    <v-tab-item :disabled="!selected[0].payment.insurance">
						<v-form style="padding:12px 32px;width:600px;" :disabled="!selected[0].payment.insurance || !!selected[0].diagConclusion" ref="diagform2">
							<v-radio-group v-model="diagConclusion" dense row :rules="acRules" class="mt-0">
								<v-radio label="通过" value="通过"></v-radio>
								<v-radio label="未通过" value="未通过" class="ml-16"></v-radio>
							</v-radio-group>
							<v-btn rounded color="primary" width="120" class="mt-2 mb-4" @click.stop="saveDiagConclusion2"
								:loading="loading" :disabled="loading||!selected[0].payment.insurance||!!selected[0].diagConclusion">提交结论</v-btn>
						</v-form>
                    </v-tab-item>
					-->
                </v-tabs-items>
	            </div>
			</pane>
		</splitpanes>
		<viewer @inited="inited" ref="viewer" :images="eeresults" style="display:none">
			<template slot-scope="scope">
				<img v-for="src in scope.images" :src="src" :key="src">
			</template>
		</viewer>
		<diagnose v-model="diagnose" :patient="selected[0]" :mrindex="mrindex" @change="fetchMr"/>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, calculateAge, today, tomorrow} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
    import Meeting2 from '@/components/Meeting2.vue'
	import DiagnoseHistory from '../components/DiagnoseHistory.vue';
	import Diagnose from '../components/Diagnose.vue';
	import EvalQ from '../components/EvalQ.vue';
	import ExamList from '../components/ExamList.vue';
	import {setPatientColor} from '../workset3'
	import PatientNote from '../components/PatientNote.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
					{text:'', value:'ho', width:28, class:'bcicon', cellClass:'bcicon'},
                    {text:'', value:'actions', width:40, sortable: false, class:'px-0', cellClass:'px-0'},
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'优惠选项', value:'payment.coupontext', width:100},
                    {text:'预约评估时间', value:'apntEvalDate', width:180, formatter: item => formatTime(item.apntEvalDate)},
					{text:'预约医生', value:'apntEvalDoctor', width:100},
                    {text:'评估方式', value:'evalway', width:100},
                    {text:'面评结论', value:'diagConclusion', width:100},
                    {text:'消化内科结论', value:'evalConclusion.operationQueue', width:150},
                    {text:'麻醉科结论', value:'evalConclusion.anesConclusion', width:150},
                    {text:'套餐内容', value:'opts', width:240, sortable: false, formatter:this.getOptsStr},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件号', value:'idcard', width:120, formatter:this.getMaskedIdcard},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'身高', value:'QA.baseinfo.bodyheight', width:80},
                    {text:'体重', value:'QA.baseinfo.bodyweight', width:80},
                    {text:'BMI', value:'bmi', width:80, formatter:this.getBMI},
                    {text:'出生日期', value:'pii.dob', width:110},
                    {text:'年龄', value:'age', width:80, formatter:this.getAge},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'付款备注', value:'payment.comment', width:200},
                    {text:'订单号', value:'_id', width:100},
                    {text:'诊所', value:'region', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				evalConclusion: {
					anesConclusion: '',
					operationQueue: '',
					gastroPrompt1: false,
					gastroPrompt2: false,
					gastroPrompt3: false,
					gastroPrompt4: false,
					notice: '',
				},
				savingConclusion1: false,
				lockedConclusion1: false,
				lockedConclusion2: false,
				historyItems: [],
				eeresults: [],
				filter: 0,
				canMeeting: false,
				isMeeting: false,
				meetingUserId: '',
				meetingRoomId: '',
				options: {sortBy:['apntEvalDate'], sortDesc:[false]},
				totalItems: 0,
				pname: '',
				acRules: [v => !!v || '此项为必选'],
				canEdit: false,
				diagnose: false,
				tableheight: undefined,
				mrindex: -1,
				porderid: this.$route?.params?.orderid,
				MedicalHistory: [],
				tab: null,
				doctors_p: [],
				evalway: null,
				diagConclusion: '',
				isED: false,
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege(['术前评估','消化内科','麻醉科']);
			if (!this.authed) return;
			this.canMeeting = this.$hasPrivilege('视频');
			this.canEdit = this.$hasPrivilege('修改评估');
			this.isED = this.$getCurrentUser().group === '内镜医生';
			this.formatTime = formatTime;
			this.fetchDoctors();
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
				setTimeout(() => {
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async fetchMr() {
				this.MedicalHistory = [];
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp2order')
						.where({
							pid: this.selected[0].pid,
							MedicalHistory: _.exists(true),
						})
						.field({MedicalHistory:true})
						.get();
					const r = res.data.find(x => x._id === this.selected[0]._id);
					if (r) {
						this.selected[0].MedicalHistory = r.MedicalHistory;
					}
					this.MedicalHistory = res.data
						.flatMap(x => {
							x.MedicalHistory.forEach((y, index) => {
								if (x._id === this.selected[0]._id) {
									y.id = x._id;
									y.mrindex = index;
								}
							});
							return x.MedicalHistory;
						})
						.sort((a, b) => a.time - b.time);
				} catch(err) {
					console.error(err);
				}
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const $ = _.aggregate;
				const filters = [
					_.and([{apntEvalDate:_.exists(true)}, {evalConclusion:_.exists(false)}]),
					_.and([{apntEvalDate:_.gt(today.begin()).lt(today.end())}, {evalConclusion:_.exists(false)}]),
					_.and([{apntEvalDate:_.gt(tomorrow.begin()).lt(tomorrow.end())}, {evalConclusion:_.exists(false)}]),
					_.and([{evalConclusion:_.exists(true)}, {'endoscopyResult.pathology' : _.neq('无')}, {'progress.pathology' : _.exists(false)}]),
				];
				let filter = [];
				if (this.evalway) {
					filter.push({evalway:this.evalway});
				}
				if (this.pname) {
					filter = [{'pii.username':this.pname}];
					this.porderid = undefined;
				} else if (this.porderid) {
					filter = [{_id:this.porderid}];
				}
				filter.push({hide:_.neq(true)});
				filter.push({orderSource:_.neq('定向')});
				const f = this.pname ? filter : filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					const pids = res.data.map(x => x.pid);
					const r = await db.collection('wp2order').aggregate()
						.match({pid:_.in(pids), 'progress.operative':_.exists(true)})
						.group({_id:'$pid', ids:$.push($.concat([
							$.dateToString({date:'$progress.operative',format: '%Y-%m-%d'}),
							' ',
							'$opts.GIE'
						]))})
						.end();
					res.data.forEach(x => {
						let a = r.data.find(y => y._id === x.pid)?.ids;
						if (a?.length > 0) {
							a = ['历史检查：', ...a];
							x.HistoryOperatives = a.join('<br>')
						}
					});
					await setPatientColor(db, res);
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			inited(viewer) {
				this.$viewer = viewer;
			},
			showImage(idx) {
				if (this.eeresults.length === 0) return;
				this.$viewer.show();
				this.$viewer.view(idx);
			},
			startMeeting() {
				const user = this.$getCurrentUser();
				if (user.name && user.title) {
					this.meetingUserId = user.title+':'+user.name;
				} else {
					this.meetingUserId = user.username;
				}
				this.meetingRoomId = this.selected[0]._id;
				this.isMeeting = true;
				this.$nextTick(async() => {
					try {
						await this.$refs['meeting'].start('评估');
					} catch(err) {
						console.error(err);
						this.isMeeting = false;
					}
				});
			},
			getText(h) {
				return h.formatter ? h.formatter(this.selected[0]) : h.value.split('.').reduce((p,c)=>p&&p[c]||null, this.selected[0])
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
//				return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{3})$/,  "\$1****\$2");
			},
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
			},
			getBMI(item) {
				if (!item || !item.QA || !item.QA.baseinfo.bodyheight) return '-';
				const h = item.QA.baseinfo.bodyheight * 0.01;
				const bmi = item.QA.baseinfo.bodyweight / (h * h);
				return bmi.toFixed(1);
			},
			async saveConclusion1() {
				if (!this.$refs.ecform.validate()) return;
                this.savingConclusion1 = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'saveEvalConclusion',
						data: {
							id,
							evalConclusion: this.evalConclusion
						}
					}});
					await this.fetchData();
					this.lockedConclusion1 = true;
				} catch(err) {
					console.error(err);
				}
				this.savingConclusion1 = false;
			},
			addRecord() {
				this.mrindex = -1;
				this.diagnose = true;
			},
			editRecord(idx) {
				this.mrindex = idx;
				this.diagnose = true;
			},
			async removeRecord(index) {
				const res = await this.$dialog.warning({
					text: '确定要删除此项记录？',
					title: '提示'
				});
				if (!res) return;
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'removeMedicalRecord',
							data: {
								id,
								index,
							}
						}
					});
					if (!res.result.ret) {
						this.$dialog.error({title:'删除失败', text:res.result.msg});
						return;
					}
					this.fetchMr();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				} finally {
					this.loading = false;
				}
			},
			async saveEvalQ() {
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'beginSaveEvalQ',
						data: {
							id,
						}
					}});
				} catch(err) {
					console.error(err);
				}
				const q = this.$refs.evalq.getValues();
				this.loading = true;
				const dialogInstance = await this.$dialog.loading({
					text: '保存中，请稍候...',
					color: '#ce5925',
				});
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'saveEvalQ',
						data: {
							id,
							evalQ: q
						}
					}});
					this.selected[0].evalQ = q;
					this.$dialog.message.success('保存完成');
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('保存失败，请重试');
				} finally {
					dialogInstance.close()
				}
				this.loading = false;
			},
			printEvalQ() {
				this.$refs.evalq.print();
			},
			printEvalMR() {
				this.$refs.evalq.printMR();
			},
			async fetchDoctors() {
				try {
					const db = this.$tcbapp.database();
					const _ = db.command;
					const res = await db.collection('wp2doctor')
						.where(_.and(
							{duty:_.in(['p','p2'])},
//							{region:_.elemMatch(_.eq(store.currentRegion))}
						))
						.orderBy('rank','desc')
						.field({name:true, duty:true})
						.get();
					this.doctors_p = res.data.filter(x => x.duty.some(y => ['p', 'p2'].includes(y))).map(x => x.name);
				} catch(err) {
					console.error(err);
				}
			},
			showHistoryOper(item) {

			},
			async saveItem(key, props) {
				await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'editInfo',
					data:{
						id: props.item._id,
						key,
						value: props.value
					}
				}});
			},
			async saveDiagConclusion() {
				this.$refs.diagform.validate();
				if (!this.$refs.diagform.validate()) return;
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'saveDiagConclusion',
							data: {
								id,
								stopac: this.evalConclusion.gastroPrompt1,	//anticoagulant
								stoph: this.evalConclusion.gastroPrompt2,  //hypoglycemic
								bcm4: this.evalConclusion.gastroPrompt3, //Bowel cleansing medicines
								canah: this.evalConclusion.gastroPrompt4, //antihypertensive
								notice: this.evalConclusion.notice,
								anes: this.evalConclusion.anesConclusion,
							}
						}
					});
					this.lockedConclusion2 = true;
					/*
					if (this.selected[0].payment.insurance === '自付') {
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname: 'preAuthorize',
								data: {
									id
								}
							}
						});
					}
					*/
                    await this.fetchData();
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async editNote(item) {
				await this.$dialog.showAndWait(PatientNote, {width:600, pid:item.pid});
				await this.fetchData();
			},
			/*
			async saveDiagConclusion2() {
				this.$refs.diagform2.validate();
				if (!this.$refs.diagform2.validate()) return;
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'saveDiagConclusion2',
							data: {
								id,
								diagConclusion: this.diagConclusion
							}
						}
					});
					if (this.selected[0].payment.insurance === '自付') {
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname: 'preAuthorize',
								data: {
									id
								}
							}
						});
					}
                    await this.fetchData();
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			*/
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.evalConclusion = newitem[0].evalConclusion || this.$options.data().evalConclusion;
				//如果有evalConclusion，但其中只有gastroPrompt3时，不能lock。所以用测试其中operationQueue的存在来判断
				this.lockedConclusion1 = (!!newitem[0].evalConclusion && !!newitem[0].evalConclusion.operationQueue) || !this.$hasPrivilege('消化内科');
				this.lockedConclusion2 = !!newitem[0].evalConclusion || !this.$hasPrivilege('消化内科');
				if (newitem[0].history) {
					this.historyItems = Object.assign(newitem[0].history);
				} else {
					this.historyItems = [];
				}
				const s1 = 'cloud://huasan-test-id.6875-huasan-test-id-1300427821';
				const s2 = 'https://6875-huasan-test-id-1300427821.tcb.qcloud.la';
				const s3 = 'cloud://huasan-6gaadaha7ceb62a9.6875-huasan-6gaadaha7ceb62a9-1300427821';
				const s4 = 'https://6875-huasan-6gaadaha7ceb62a9-1300427821.tcb.qcloud.la';
				this.eeresults = (newitem[0].eeresults || []).map(x => x.replace(s1, s2).replace(s3, s4));
				this.diagConclusion = newitem[0].diagConclusion;
				this.fetchMr();
			}
		},
        computed: {
			onlyW() {
				if (this.selected.length === 0) return;
				return this.selected[0].opts.GIE === '胃镜';
			},
		},
        components: { Splitpanes, Pane, Meeting2, DiagnoseHistory, Diagnose, EvalQ, ExamList }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
.bcicon {
	padding: 0!important;
}
</style>