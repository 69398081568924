<template>
    <div>
		<v-btn-toggle mandatory dense v-model="vpsize" class="mr-4">
			<v-btn>小</v-btn>
			<v-btn>中</v-btn>
			<v-btn>大</v-btn>
		</v-btn-toggle>
		<v-btn icon outlined @click="togglemute"><v-icon>{{muted ? 'mic_off' : 'mic_none'}}</v-icon></v-btn>
        <v-btn color="primary" @click="leave" class="ml-4">结束会话</v-btn>
		<v-progress-linear :active="loading" :indeterminate="loading"/>
		<v-snackbar v-model="snackbar" top color="error">
			{{errmsg}}
			<template v-slot:action="{ attrs }">
				<v-btn text	v-bind="attrs" @click="snackbar = false">关闭</v-btn>
			</template>
		</v-snackbar>
        <div class="d-flex">
            <div id="localstream" class="vp" :style="vpstyle">
				<div class="title-bar">
					<div class="title-item-container">
						<div class="title-item">
							{{userid}}
						</div>
					</div>
				</div>
			</div>
            <div v-for="rs in remoteStream_" :id="rs.id" class="vp" :style="vpstyle">
				<div class="title-bar">
					<div class="title-item-container">
						<div class="title-item">
							{{rs.userText}}
						</div>
					</div>
				</div>
			</div>
        </div>
    </div>
</template>

<script>
	import { Base64 } from '../base64'
    import TRTC from 'trtc-js-sdk'
    TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.WARN);
    TRTC.Logger.enableUploadLog();
    console.log(TRTC);

    export default {
        props: {
            userid: {
                type: String,
                default: ''
            },
            roomid: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                client_: null,
                localStream_: null,
                remoteStream_: [],
				vpsize: 1,
                loading: false,
				snackbar: false,
				errmsg: '',
				muted: false
            }
        },
        mounted() {
        },
        async beforeDestroy() {
			await this.releaseRes();
        },
        methods: {
			togglemute() {
				if (!this.localStream_) return;
				const muted = !this.muted;
				if (muted) {
					if (this.localStream_.muteAudio()) this.muted = true;
				} else {
					if (this.localStream_.unmuteAudio()) this.muted = false;
				}
			},
            async start(category) {
                if (this.client_) return;
                if (!this.userid || !this.roomid) return;
                try {
					this.loading = true;
                    await this.join();
                    await this.$tcbapp.callFunction({name:"wp2mp",
                        data:{
                            funcname: 'openroom',
                            data: {
                                initiator: this.userid,
                                id: this.roomid,
                                category
                            }
                        }
                    });
				} catch(err) {
					console.error(err);
					if (err.name === 'NotReadableError') {
						this.errmsg = '摄像头或麦克风被其他应用程序占用';
					} else {
						this.errmsg = err.message;
					}
					this.snackbar = true;
					await this.releaseRes();
                } finally {
					this.loading = false;
                }
            },
            async join() {
				const userId = Base64.encodeURI(this.userid);
                const config = await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'makeUserSig', data:{userId}}});
                this.client_ = TRTC.createClient({
                    mode: 'rtc',
                    userId,
                    useStringRoomId:true,
                    ...config.result
                });
                this.client_.on('error', err => {
					console.error('video client error');
                    console.error(err);
                });
                this.client_.on('stream-added', evt => {
                    this.client_.subscribe(evt.stream);
                });
                this.client_.on('stream-subscribed', evt => {
                    const remoteStream = evt.stream;
                    const id = 'rs' + remoteStream.getId();
					const userText = Base64.decode(remoteStream.getUserId());
                    this.remoteStream_.push({id, userText});
                    this.$nextTick(() => {
                        remoteStream.play(id);
                    })
                });
                this.client_.on('stream-removed', evt => {
                    const remoteStream = evt.stream;
                    const id = 'rs' + remoteStream.getId();
                    this.remoteStream_ = this.remoteStream_.filter(stream => stream.id !== id);
                });
                await this.client_.join({roomId: 'HS' + this.roomid});
                console.log('join room success');
                this.localStream_ = TRTC.createStream({
                    audio: true,
                    video: true,
                    userId,
                    mirror: true
                });
                await this.localStream_.initialize();
                console.log('initialize local stream success');
                this.localStream_.on('player-state-changed', event => {
                    console.log(`local stream ${event.type} player is ${event.state}`);
                });
                await this.client_.publish(this.localStream_);
                this.localStream_.play('localstream');
            },
			async releaseRes() {
                if (this.client_) {
                    try {
                        await this.$tcbapp.callFunction({name:"wp2mp",
                            data:{
                                funcname: 'closeroom',
                                data: {
                                    id: this.roomid,
                                    operator: this.userid
                                }
                            }
                        });
                    } catch(err) {
                        console.error(err);
                    }
                    try {
                        if (this.localStream_) {
                            await this.client_.leave();
                            this.client_ = null;
                            this.localStream_.stop();
                            this.localStream_.close();
                            this.localStream_ = null;
                            this.remoteStream_ = [];
                        }
                    } catch(err) {
                        console.error(err);
                    }
                }
			},
            async leave() {
				await this.releaseRes();
                this.$emit('leave');
            }
        },
		computed: {
			vpstyle() {
				return {width:(this.vpsize+2)*120+'px',height:(this.vpsize+2)*120+'px'}
			}
		}
    }
</script>

<style scoped>
    .vp {
		position: relative;
        margin: 2px;
    }
	.vp .title-bar {
		position: absolute;
		z-index: 100;
		top: 6px;
		left: 6px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: left;
	}
	.title-bar .title-item-container {
		width: auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		background: rgb(0, 0, 0, .3);
		border-radius: 10px;
	} 
	.vp .title-bar .title-item {
		width: 200px;
		height: 30px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #fff;
		font-size: 14px;
	}
</style>