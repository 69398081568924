<template>
	<div>
		<v-container>
			<v-row justify="start">
				<v-col md="4">
					<v-form style="padding:12px 0;margin:0 8px;" :readonly="lockedExam1">
						<v-treeview selectable open-all dense :items="examlistGrouped1" v-model="examItems1t">
							<template v-slot:label="{item}">
								{{item.id}}<v-icon v-if="getDetail(item.id).length > 0" class="material-icons-outlined ml-1" @click.stop="showDetail(item.id)">list_alt</v-icon>
							</template>
						</v-treeview>
						<v-btn color="primary" width="120" class="ml-6 mt-4 mb-4" @click.stop="submitExam1"
							:loading="loading" :disabled="loading||lockedExam1">提交</v-btn>
						<v-btn v-if="editable" color="primary" outlined width="120" class="ml-4 mt-4 mb-4"
							@click.stop="edit" :disabled="!lockedExam1">编辑</v-btn>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
		<v-dialog v-model="detaildialog" width="360">
			<v-card>
				<v-card-title>{{curdetail}}</v-card-title>
				<v-card-text>
					<v-list dense>
						<v-list-item v-for="(item,idx) in getDetail(curdetail)" :key="idx">
							{{idx+1}}、{{item}}
						</v-list-item>
					</v-list>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: {
		patient: Object,
	},
	watch: {
		patient(v) {
			this.setValues(v);
		}
	},
	data() {
		return {
			editable: false,
			loading: false,
			lockedExam1: false,
			detaildialog: false,
			curdetail: '',
			examlistGrouped1: [],
			examItems1t: [],
		}
	},
	created() {
	    const workset = JSON.parse(window.sessionStorage.getItem('workset'));
		this.examlist = workset.examlist;
		this.examlistGrouped1 = this.makeTree(workset.examlist);
	},
	mounted() {
		this.editable = this.$hasPrivilege('修改检查单');
		this.setValues(this.patient);
	},
	methods: {
		setValues(v) {
			if (v && v.examlist) {
				this.examItems1t = v.examlist.filter(x=>x.value).map(x=>x.label);
				this.setTreeDisabled(this.examlistGrouped1, true)
				this.lockedExam1 = true;
			} else {
				this.examItems1t = [];
				this.setTreeDisabled(this.examlistGrouped1, false)
				this.lockedExam1 = false;
			}
		},
		makeTree(examlist) {
			return examlist.reduce((acc,cur) => {
				const d = { id:cur.title, name:cur.title, detail:cur.detail, disabled:false, deprecated:false };	//2024-02-28 李奇云飞书
//				const d = { id:cur.title, name:cur.title, detail:cur.detail, disabled:cur.deprecated, deprecated:cur.deprecated };
				let e = acc.find(x => x.id === cur.category);
				if (e) {
					e.children.push(d);
				} else {
					acc.push({ id:cur.category, name:cur.category, children:[d], disabled:false });
				}
				return acc;
			}, []);
		},
		setTreeDisabled(tree, disabled) {
			for(let x of tree) {
				x.disabled = disabled || x.deprecated
			}
		},
		showDetail(c) {
			this.curdetail = c;
			this.detaildialog = true;
		},
		getDetail(c) {
			if (!c) return [];
			const d = this.examlist.find(x=> x.title === c);
			return (d && d.detail) || [];
		},
		async submitExam1() {
			this.loading = true;
			const id = this.patient._id;
			const examlist = this.examlist.map(x=>({label:x.title, value:this.examItems1t.includes(x.title)}));
			try {
				await this.$tcbapp.callFunction({name:"wp2mp",
					data:{
						funcname:'saveExamList',
						data: {
							id,
							examlist
						}
					}
				});
				this.lockedExam1 = true;
				this.setTreeDisabled(this.examlistGrouped1, true)
				this.$dialog.message.success('保存完成');
			} catch(err) {
				console.error(err);
				this.$dialog.message.error('保存失败');
			}
			this.loading = false;
		},
		edit() {
			this.lockedExam1 = false;
			this.setTreeDisabled(this.examlistGrouped1, false);
		},
	},
}
</script>